import React, { useState, useEffect } from "react";
import { Slider, Typography, Select, MenuItem } from "@mui/material";
import { patchApi } from "../../utils/fetchUtils"; // Adjust import as needed

export enum VIEW {
  FRONT = "front_view",
  SIDE = "side_view",
  TOP = "top_view",
}

interface Position {
  x: number;
  y: number;
}

interface ProductReferenceData {
  id: string;
  name: string;
  front_view: string;
  side_view: string;
  top_view: string;
  camera_distance: number;
}

interface ImageComparisonProps {
  productFrontImageUrl: string;
  productSideImageUrl: string;
  productTopImageUrl: string;
  cameraDistance: number;
  products: ProductReferenceData[];
  product: any;
}

const ImageComparison: React.FC<ImageComparisonProps> = ({
  productFrontImageUrl,
  productSideImageUrl,
  productTopImageUrl,
  cameraDistance,
  products,
  product,
}) => {
  const [view, setView] = useState<VIEW>(VIEW.FRONT);
  const [selectedReferenceProduct, setSelectedReferenceProduct] =
    useState<ProductReferenceData>(products[0]); // Default to first product
  const [productScale, setProductScale] = useState<number>(product?.scale || 1);
  const [productPosition, setProductPosition] = useState<Position>({ x: 0, y: 0 });
  const [referencePosition, setReferencePosition] = useState<Position>({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    if (product?.scale) {
      setProductScale(product.scale);
    }
  }, [product]);

  const productImages = {
    [VIEW.FRONT]: productFrontImageUrl,
    [VIEW.SIDE]: productSideImageUrl,
    [VIEW.TOP]: productTopImageUrl,
  };

  const adjustedScale =
    productScale * (cameraDistance / selectedReferenceProduct.camera_distance);

  const handleDrag = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    setPosition: React.Dispatch<React.SetStateAction<Position>>
  ) => {
    event.preventDefault();

    let startX = event.clientX;
    let startY = event.clientY;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startX = moveEvent.clientX;
      startY = moveEvent.clientY;
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const updateProductScale = async (newScale: number) => {
    if (product?.id) {
      const payload = { scale: newScale };

      try {
        await patchApi(`products/${product.id}/`, payload);
      } catch (error) {
        console.error("Failed to update product scale:", error);
      }
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "600px",
        backgroundColor: "#f0f0f0",
        overflow: "hidden",
      }}
    >
      <Typography variant="h6" style={{ textAlign: "center", marginBottom: "10px" }}>
        Image Comparison
      </Typography>

      {/* Product Image */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "30%",
          transform: `translate(${productPosition.x}px, ${productPosition.y}px) scale(${adjustedScale})`,
          transformOrigin: "center",
          cursor: "grab",
        }}
        onMouseDown={(e) => handleDrag(e, setProductPosition)}
      >
        <img
          src={productImages[view]}
          alt="Product"
          style={{ width: "200px", height: "auto", display: "block" }}
        />
      </div>

      {/* Reference Image */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "70%",
          transform: `translate(${referencePosition.x}px, ${referencePosition.y}px)`,
          transformOrigin: "center",
          cursor: "grab",
        }}
        onMouseDown={(e) => handleDrag(e, setReferencePosition)}
      >
        <img
          src={selectedReferenceProduct[view]} // Dynamically select based on view
          alt="Reference"
          style={{ width: "200px", height: "auto", display: "block" }}
        />
      </div>

      {/* Scale Slider */}
      <div
        style={{
          position: "absolute",
          bottom: "90px", // Adjusted to make space for dropdowns
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
        }}
      >
        <Slider
          value={productScale}
          min={0.1}
          max={5}
          step={0.1}
          onChange={(_, newValue) => {
            setProductScale(newValue as number);
            updateProductScale(newValue as number);
          }}
          aria-labelledby="scale-slider"
        />
      </div>

      {/* Dropdown Container */}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Dropdown to Select View */}
        <div style={{ textAlign: "center" }}>
          <Typography>Select View:</Typography>
          <Select
            value={view}
            onChange={(e) => setView(e.target.value as VIEW)}
            style={{ width: "150px", marginTop: "10px" }}
          >
            <MenuItem value={VIEW.FRONT}>Front View</MenuItem>
            <MenuItem value={VIEW.SIDE}>Side View</MenuItem>
            <MenuItem value={VIEW.TOP}>Top View</MenuItem>
          </Select>
        </div>

        {/* Dropdown to Select Reference Product */}
        <div style={{ textAlign: "center" }}>
          <Typography>Select Reference Product:</Typography>
          <Select
            value={selectedReferenceProduct.id}
            onChange={(e) =>
              setSelectedReferenceProduct(
                products.find((p) => p.id === e.target.value) || products[0]
              )
            }
            style={{ width: "150px", marginTop: "10px" }}
          >
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default ImageComparison;
