import React, { useContext, useEffect, useState } from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  TypographyBodyLarge,
  TypographyBodySmallNormal,
} from "../StyledComponets/Typography";
import { AsyncButton, PrimaryButton } from "../StyledComponets/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { SnackbarContext } from "../Snackbar";
import { createFolder } from "../../store/folder";
import FormFieldsWithType from "components/StyledComponets/FormFieldsWithType";
import folderFields from "containers/folder/constants";
import { uploadAssetS3 } from "store/assetForm";
import { useQueryClient } from "@tanstack/react-query";

type ModalProps = {
  onClose: () => void;
  open: boolean;
  onSubmit?: () => void;
};

const CreateFolder = (props: ModalProps) => {
  const { onClose, open } = props;
  const dispatch = useAppDispatch();
  const { showSnackbar } = useContext(SnackbarContext);
  const [formState, setFormState] = useState<any>({});
  const [payload, setPayload] = useState<any>({});
  const [error, setError] = useState(false);
  const assetFormState: any = useAppSelector((store) => store.assetForm);
  const queryClient = useQueryClient();

  const handleValueUpdate = (val: any, property: any) => {
    setFormState((prev: any) => {
      return { ...prev, [property]: val };
    });
    setPayload((prev: any) => {
      let temp = prev;
      if (folderFields?.properties?.[property]?.fieldType == "singleSelect") {
        temp = { ...temp, [property]: val?.id };
      } else if (
        folderFields?.properties?.[property]?.fieldType == "multiSelect"
      ) {
        temp = { ...temp, [property]: val?.map((item: any) => item.id) };
      } else if (
        folderFields?.properties?.[property]?.fieldType == "fileUpload"
      ) {
      } else {
        temp = { ...temp, [property]: val };
      }
      if (
        property == "created_by" &&
        folderFields?.properties?.[property]?.last_updated_by
      ) {
        temp = { ...temp, last_updated_by: val?.id };
      }
      return { ...temp };
    });
    if (folderFields?.properties?.[property]?.fieldType === "fileUpload") {
      dispatch(
        uploadAssetS3({
          file: val,
          callback: (data: any) => {
            setPayload((prev: any) => {
              return { ...prev, [property]: data?.s3_uri };
            });
          },
        })
      );
    }
  };

  const onSubmit = () =>
  // new Promise<void>((resolve) => {
  {
    if (!payload?.name?.match(/^[a-zA-Z\d ]+$/)) {
      throw "Invalid Folder name.";
    }
    let error = false;
    Object.keys(folderFields?.properties).forEach((property: string) => {
      if (
        folderFields?.properties?.[property]?.required &&
        !!!payload?.[property]
      ) {
        error = true;
      }
    });
    setError(error);
    if (error) {
      return;
    }

    dispatch(
      createFolder(
        { ...payload, name: payload?.name.trim() },
        showSnackbar,
        (folder) => {
          if (folder) {
            queryClient.invalidateQueries({ queryKey: ['/folders'] })
          }
          onClose();
        }
      )
    );
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          background: "var(--color-palette-gray-800, #253042)",
          borderRadius: "16px",
          padding: "32px",
          width: "50vw",
          maxWidth: "1200px",
          minHeight: "128px",
          maxHeight: "92vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          overflow: "hidden",
          gap: "1.5rem",
        },
      }}
    >
      <Box component="div"
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        maxHeight={"85vh"}
        gap={"1rem"}
      >
        <Grid
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          gap={4}
          width={"100%"}
        >
          <TypographyBodyLarge
            sx={{
              color: "var(--color-palette-white, #FFF)",
              alignSelf: "center",
            }}
          >
            {"New Folder"}
          </TypographyBodyLarge>
          <CloseIcon
            sx={{
              width: "32px",
              height: "32px",
              padding: "4px",
              color: "white",
              bgcolor: "#1A202B",
              borderRadius: "100px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          gap={2}
          overflow={"auto"}
          height={"80vh"}
          width={"100%"}
          paddingRight={"0.5rem"}
        >
          {Object.keys(folderFields?.properties).map((property, index) => {
            
            return (
              <FormFieldsWithType
                key={index}
                property={{
                  ...folderFields?.properties?.[property],
                  ...(folderFields?.properties?.[property]?.optionsType ==
                    "dynamic"
                    ? {
                      options:
                        assetFormState?.[
                        folderFields?.properties?.[property]?.optionApi
                        ]?.data,
                    }
                    : {}),
                }}
                value={formState[property]}
                setValue={(val: any) => handleValueUpdate(val, property)}
              />
            )
          }

          )}
        </Grid>
        <TypographyBodySmallNormal
          sx={{ color: "var(--color-palette-gray-500, #7D899C)" }}
        >
          {payload?.name?.trim().length > 0 && (
            <>
              {`Creating `}
              <span
                style={{
                  fontWeight: "700",
                  color: "var(--color-palette-gray-400, #AEB6C4)",
                  letterSpacing: "0.15px",
                }}
              >
                {payload?.name?.trim()}
              </span>
            </>
          )}
        </TypographyBodySmallNormal>
        <Box component="div"
          display={"flex"}
          justifyContent={"space-between"}
          paddingRight={"1.5rem"}
        >
          <Box component="div" display={"flex"} gap={"0.2rem"}>
            {error && (
              <>
                <Typography color={"red"}>*</Typography>
                <Typography
                  color={"white"}
                  fontStyle={"italic"}
                  fontWeight={200}
                >
                  Please enter all the required fields
                </Typography>
              </>
            )}
          </Box>

          <PrimaryButton onClick={onSubmit}>Create Folder</PrimaryButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateFolder;
