import React, { useContext, useEffect, useState } from "react";
import { getApi, postApi, deleteApi } from "../../utils/fetchUtils";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  Box,
  Icon,
  TextField,
  Button,
  Checkbox,
  TableFooter,
} from "@mui/material";
import ThreeDViewer from "./ThreeDViewer";
import ImageComparision from "./ImageComparision";

interface ThreeDViewerModalProps {
  open: boolean;
  onClose: () => void;
  modelUrl: string;
  product: any;
}

interface ProductData {
  id: string;
  name: string;
  front_view: string;
  side_view: string;
  top_view: string;
  camera_distance: number;
}

const products: ProductData[] = [
  {
    id: "1f5d5f95",
    name: "HumanRef",
    front_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/66c08b5b-c00c-400c-843c-d482196aae86_HumanRef_frontView.png",
    side_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/3831b5af-615c-4ad7-94bd-7abf89992ff5_HumanRef_sideView.png",
    top_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/24c23fdb-0e02-4977-9f32-f3e7ed1723f6_HumanRef_topView.png",
    camera_distance: 1.3419790542684495,
  },
  {
    id: "cf8dd5d8",
    name: "Card",
    front_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/6cee6118-105a-4b00-b22c-c3d735890518_Card_frontView.png",
    side_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/25e81daf-cb1c-4d6f-a6f7-a1f7c2a4fc7b_Card_sideView.png",
    top_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/403db86f-021e-4e48-add8-f84771a43319_Card_topView.png",
    camera_distance: 0.053244690375,
  },
  {
    id: "e66a4ef3",
    name: "TrafficCone",
    front_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/d4874f5f-d288-4a47-a2fe-42f065b0dbf1_TrafficCone_frontView.png",
    side_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/345a9899-9db1-4e0a-ad8e-4c044bd7f548_TrafficCone_sideView.png",
    top_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/b1b4c2e2-ce71-43cc-97c9-f09acc32a53d_TrafficCone_topView.png",
    camera_distance: 0.38912106441749994,
  },
  {
    id: "6c642ed1",
    name: "A4Paper",
    front_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/5a87c776-a9c9-4604-8ed1-e62e2f5c4b47_A4Paper_frontView.png",
    side_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/83aa1789-b71e-4aa5-8e6f-ba993a68a998_A4Paper_sideView.png",
    top_view:
      "https://d2yydnugieiyqv.cloudfront.net/files/9446f1f9-90b8-4ad6-82ea-29c12bce987c_A4Paper_topView.png",
    camera_distance: 0.16152304816125002,
  },
];

const ThreeDViewerModal: React.FC<ThreeDViewerModalProps> = ({
  open,
  onClose,
  modelUrl,
  product,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="div"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          background: "var(--color-palette-gray-800, #253042)",
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        {/* <ThreeDViewer modelUrl={modelUrl.replace("https://d2yydnugieiyqv.cloudfront.net/files/", "http://localhost:8005/")} /> */}
        {/* <ThreeDViewer modelUrl={modelUrl} /> */}
        <ImageComparision
          productFrontImageUrl={product?.front_view}
          productSideImageUrl={product?.side_view}
          productTopImageUrl={product?.top_view}
          cameraDistance={product?.camera_distance}
          products={products}
          product={product}
        />
      </Box>
    </Modal>
  );
};

export default ThreeDViewerModal;
