import React, { useState } from "react";
import { Box, InputAdornment, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FolderIcon from "@mui/icons-material/Folder";
import { KeyboardArrowUp } from "@mui/icons-material";
import { Folder } from "../../../utils/types";
import CreateFolder from "../../../components/Modals/CreateFolder";
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from "hooks/store";

type DialogTextFieldProps = {
 
  folder: string;
  setFolder: Function;
  className?: string;
};

const DialogSelectField = ({
  
  folder,
  setFolder,
  className,
}: DialogTextFieldProps) => {
  const [open, setOpen] = useState(false);
  const [openAddFolder, setOpenAddFolder] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onToggleAddFolder = () => setOpenAddFolder(!openAddFolder);
  const folders = useAppSelector(store => store.folder.allFolders ?? {});
  const displayFolders: Folder[] = [
      ...Object.values(folders),
  ];

  return (
    <>
      <Select
        displayEmpty
        placeholder="abc"
        value={folder}
        open={open}
        className={className}
        onChange={(e) => {
          setFolder(e.target.value);
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        IconComponent={() =>
          open ? (
            <KeyboardArrowUp
              onClick={handleClose}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={handleOpen}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          )
        }
        startAdornment={
          <InputAdornment position="end">
            <FolderIcon
              onClick={() => (open ? handleClose() : handleOpen())}
              sx={{
                color: "#F8B407",
                marginLeft: "6px",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "Without label",
        }}
        sx={{
          width: "280px",
          height: "44px",
          padding: "px !important",
          borderRadius: "8px",
          backgroundColor: "#1A202B",
          ".MuiOutlinedInput-notchedOutline": {
            border: "0px",
            borderWidth: "0px",
          },
          ".MuiInputBase-input": {
            padding: "0px 12px !important",
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
          },
          ".MuiOutlinedInput-root:focus": { border: 0 },
          "&:hover": {
            backgroundColor: "#394455 !important",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#253042",
              "& .MuiMenuItem-root": {
                padding: 0,
              },
            },
          },
        }}
      >
        {displayFolders.map((item, index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                paddingBottom: "0px !important",
                color: "#FFFFFF !important",
                fontSize: "14px !important",
                fontWeight: 400,
                lineHeight: "22px !important",
                padding: "8px 16px !important",
                "&:hover": {
                  backgroundColor: "#394455 !important",
                },
              }}
              value={item.id}
            >
              {item.name}
            </MenuItem>
          );
        })}
        <div>
          <Box component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              border: "2px dashed var(--color-palette-gray-700, #394455)",
              height: "50px",
              "&:hover": {
                backgroundColor: "#394455 !important",
              },
            }}
            onClick={onToggleAddFolder}
          >
            <div style={{
              height: '24px',
              width: '24px',
              borderRadius: '4px',
              background: '#394455',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: "8px",

            }} >
              <AddIcon sx={{ color: '#7D899C', fontSize: '1rem' }} />
            </div>
            <MenuItem
              sx={{
                color: "#FFFFFF !important",
                fontSize: "14px !important",
                fontWeight: 700,
                lineHeight: "22px !important",
                padding: "0px 16px !important", // Adjusted padding to remove extra space
                "&:hover": {
                  backgroundColor: "#394455 !important",
                },
              }}
            >
              Create a new folder
            </MenuItem>
          </Box>
        </div>
      </Select>
      {openAddFolder && (
        <CreateFolder open={openAddFolder} onClose={onToggleAddFolder} />
      )}
    </>
  );
};

export default React.memo(DialogSelectField);
