import { capitalize } from "@mui/material";
import { Asset, Output, SceneAsset, showSnackbarType } from "./types";
import uuid from "react-uuid";
import { EXPORT_OPTIONS, SEVERITY } from "./enums";
import JSZip from "jszip";

export function copyToClipboard(text: string, showSnackbar: showSnackbarType, object?: string) {
    navigator.clipboard.writeText(text);
    showSnackbar(`JSON copied to clipboard`);
}

export const enumToName = (slug: string) => slug.toLowerCase()
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .split(" ")
    .map(capitalize)
    .join(" ") ?? "";

export const getUniqueId = uuid;

function getTextBlob(text: string, type?: EXPORT_OPTIONS) {
    if (type === EXPORT_OPTIONS.CSV) {
        return new Blob([text], { type: 'text/csv;charset=utf-8;' });
    }
    return new Blob([text], { type: 'text/plain;charset=utf-8;' });
}

export function downloadFiles(files: Asset[] | Output[], showSnackbar: showSnackbarType, text?: string, textType?: EXPORT_OPTIONS) {

    if (files.length === 1 && !text) {
        return fetch(files[0].url)
            .then(response => response.blob())
            .then(blob => {
                const objectUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectUrl;
                a.download = files[0].fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error(error);
                showSnackbar("Something went wrong!", SEVERITY.ERROR);
            })
    }

    const zip = new JSZip();
    if (text && textType) {
        const blob = getTextBlob(text, textType);
        zip.file(`texts${textType === EXPORT_OPTIONS.CSV ? ".csv" : ".txt"}`, blob);
    }

    return Promise.all(files.map(async (file: Asset | Output) => {
        const response = await fetch(file.url);
        const blob = await response.blob();
        zip.file(file.fileName, blob);
    }))
        .then(() => zip.generateAsync({ type: "blob" }))
        .then((zipBlob) => {
            const objectUrl = window.URL.createObjectURL(zipBlob);
            const a = document.createElement("a");
            a.href = objectUrl;
            a.download = "files.zip";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            console.error(error);
            showSnackbar("Something went wrong!", SEVERITY.ERROR);
        })
};
export function convertHexToRgba(hexCode: string, opacity = 1) {

    var hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16);

    return { r: r / 255, g: g / 255, b: b / 255, a: opacity };
}
export function checkValidHex(hex: string) {
    const regex = new RegExp(/^#[0-9A-F]{6}[0-9a-f]{0,2}$/i);
    return regex.test(hex)
}
export function Rgba2UnrealColor(color: { r: number, g: number, b: number, a: number }) {
    return { r: color.r / 255, g: color.g / 255, b: color.b / 255, a: color.a }
}
export function unrealColor2Rgba(color: { r: number, g: number, b: number, a: number }) {
    return { r: color.r * 255, g: color.g * 255, b: color.b * 255, a: color.a }
}
export function rgba2hex(color: { r: number, g: number, b: number, a: number }) {
    var r = Math.round(color.r).toString(16);
    var g = Math.round(color.g).toString(16);
    var b = Math.round(color.b).toString(16);
    var a = Math.round(color.a * 255).toString(16);

    if (r.length === 1)
        r = "0" + r;
    if (g.length === 1)
        g = "0" + g;
    if (b.length === 1)
        b = "0" + b;
    if (a.length === 1)
        a = "0" + a;

    return "#" + r + g + b + a;
}
export const getPayloadScene = (scenes: SceneAsset[], duration: number | null) => {
    return scenes.map(({ name, id, thumbnail, tags, scope }, index) => ({
        name: name,
        url: thumbnail,
        duration: duration,
        tags,
        scope,
        thumbnail: thumbnail,
        index,
        map: id

    }))
}

export const templateToscene = (template: SceneAsset, duration: number | null, index: number, sceneId:any) => {
    return {
        name: template.name,
        url: template.thumbnail,
        duration: duration,
        tags: template.tags,
        scope: template.scope,
        thumbnail: template.thumbnail,
        index: index,
        map: template.map,
        id: sceneId,
        map_detail: template
    }
}

export function convertCamelCaseToWords(text: string): string {
    // Use a regular expression to add a space before each uppercase letter, except the first one.
    return text.replace(/([A-Z])/g, ' $1').trim();
  }